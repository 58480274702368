import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { MdLocationOn, MdEmail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { Button, Form, Input, message } from "antd";
import { postData } from "utils/fetchData";
import Link from "next/link";
const Ulasim = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const key = "updatable";

  const onFinish = async (values) => {
    try {
      message
        .loading({
          content: "Gönderiliyor...",
          key,
          duration: 2,
        })
        .then(async () => {
          await postData("/Site/Contact", values, false);
          form.resetFields();
          message.success({
            content: "Mesajınız başarıyla gönderildi.",
            key,
            duration: 2,
          });
        });
    } catch (err) {
      console.info("error", err);
    }
  };
  return (
    <div id="ulasim">
      <div className="ulasim">
        <h1>İletişim</h1>
        <h1>Başkana Mesaj Gönder</h1>
      </div>
      <div className="ulasim-satir">
        <Container>
          <Row>
            <Col md={6} xs={12}>
              <div className="adres-bilgisi">
                <div className="icon-arka-pilan  adres">
                  <MdLocationOn className="icon" />
                </div>
                <div className="info">
                  <h2>Adres</h2>
                  <Link href="javascript:void(0)">
                    <a>Viranşehir Belediyesi</a>
                  </Link>
                  {/* <p>Viranşehir Belediye Başkanı</p> */}
                </div>
              </div>
              <div className="adres-bilgisi">
                <div className="icon-arka-pilan eposta">
                  <MdEmail className="icon" />
                </div>
                <div className="info">
                  <h2>E-posta</h2>
                  <Link href="mailto:info@salihekinci.com">
                    <a>info@salihekinci.com</a>
                  </Link>
                </div>
              </div>
              {/* <div className="adres-bilgisi">
                <div className="icon-arka-pilan  tel">
                  <BsTelephoneFill className="icon" />
                </div>
                <div className="info">
                  <h2>Telefon</h2>
                  <Link href="tel:+123 456 7890">
                    <a>+123 456 7890</a>
                  </Link>
                </div>
              </div> */}
            </Col>
            <Col md={6} xs={12}>
              <div className="bilgi-giris">
                <Form onFinish={onFinish} form={form}>
                  <Row>
                    <Col md={6} xs={6}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Adınızı Giriniz!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Adınız"
                          className="info-input"
                          id="name"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6} xs={6}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Email Adresinizi Giriniz!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="E-posta adresiniz"
                          className="info-input"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={6}>
                      <Form.Item
                        name="phoneNumber"
                        rules={[
                          {
                            validator: (_, value) => {
                              var phoneNumber =
                                /(05)[0-9][0-9]([0-9]){3}([0-9]){2}([0-9]){2}/;

                              if (value === undefined || value === "") {
                                return Promise.reject(
                                  "Lütfen Telefon Numaranızı Giriniz!"
                                );
                              }
                              if (
                                value.length < 11 ||
                                value.length > 11 ||
                                (value.length == 11 && !phoneNumber.test(value))
                              ) {
                                return Promise.reject(
                                  new Error(
                                    "Geçerli Bir Telefon Numarası Giriniz"
                                  )
                                );
                              }
                              if (
                                value.length == 11 &&
                                phoneNumber.test(value)
                              ) {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder="Telefonunuz"
                          className="info-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6} xs={6}>
                      <Form.Item
                        name="subject"
                        rules={[
                          {
                            required: true,
                            message: "Lütfen Konu Başlığı Giriniz",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Konu Özeti"
                          className="info-input"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12}>
                      <Form.Item name="message" showCount maxLength={2500}>
                        <TextArea
                          placeholder="Mesajını Buraya Yazınız"
                          className="info-input"
                          maxLength={2500}
                          autoSize={{ minRows: 8, maxRows: 8 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Button className="btn" type="primary" htmlType="submit">
                    GÖNDER
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default Ulasim;
